import React from 'react';
import ReactDOM from 'react-dom/client';
import { SymbolChart, WinChart } from './chats/symbol';
import './index.css';
import reportWebVitals from './reportWebVitals';

const chart = ReactDOM.createRoot(document.getElementById('chart_content'));
chart.render(
  <React.StrictMode>

    <ul className="nav nav-tabs" id="argoTabs" role="tablist">
        <li className="nav-item">
            <a className="nav-link active" id="nav-tab-1" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true"><i className="fas fa-list"></i>WIN (MINI ÍNDICE)</a>
        </li>

    </ul>
    <div className="tab-content" id="argoTabsContent">            
    <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
        <div className="row">
            <SymbolChart symbol="WIN"/>
        </div>
    </div> 

    
    </div> 

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
