import React from "react";
import { sendServerPostWithQuery } from "../utils/sendr";
import { ChartBase } from "./base";

export class SymbolChart extends React.Component{
    constructor(props){
        super(props);
        this.state={data:null}
    }
    async componentDidMount(){
         try{
            console.log(JSON.stringify({symbol:"WIN"}));
            let result=await sendServerPostWithQuery("https://arch.gptblog.com.br/mt5/getResults",{symbol:this.props.symbol});
            if(!result.data.is_ok)
              throw result;
            this.setState({data:result.data.data})  
         }catch(e){
           
         }
    }

    render(){
        return(
            <ChartBase loading={!Boolean(this.state.data)} data={this.state.data||[]}/>
        )
    }
}