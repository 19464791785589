export function sendServerRequest(url,data,type='POST'){
    const request = new XMLHttpRequest();
    request.open(type, url);    
    if(!(data instanceof FormData))
      request.setRequestHeader("Content-type",'aplication/json');
    request.send(data);
    return new Promise((resolve,reject)=>{
        request.onreadystatechange=orsc;
        function orsc(r){
           const target=r.currentTarget;
           if(target.readyState==4){
               if(target.status>=200 && target.status<300){
                   const data=JSON.parse(target.responseText);
                   resolve({status:target.status,data:data,target:target});
               }
               reject({target:target});
           }                
        }
    });
}



export function sendServerPostWithQuery(url,data){
    const request = new XMLHttpRequest();
    let sendUrl=url+'?'+getQueryfromObj(data);
    console.log(sendUrl);
    request.open("POST", sendUrl);    
    request.setRequestHeader("Content-type",'text/plain');
    request.send(data);
    return new Promise((resolve,reject)=>{
        request.onreadystatechange=orsc;
        function orsc(r){
           const target=r.currentTarget;
           if(target.readyState==4){
               if(target.status>=200 && target.status<300){
                   const data=JSON.parse(target.responseText);
                   resolve({status:target.status,data:data,target:target});
               }
               reject({target:target});
           }                
        }
    });
}

function getQueryfromObj(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }