import React from 'react';
//mport { sendServerRequest } from '../utils/sendr';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
} from 'chart.js';
import { Chart,Pie } from 'react-chartjs-2';

ChartJS.register(
   LinearScale,
   ArcElement,
   CategoryScale,
   BarElement,
   PointElement,
   LineElement,
   Legend,
   Tooltip,
   LineController,
   BarController,
   RadialLinearScale,
 );

export class ChartBase extends React.Component{
   constructor(props){
      super(props);
   }
   getExtremitySequence(data,fn){
        let extremity=0;
        for(let value of data){
            let test=extremity+value;
            
        }
   }
   getData(){
      let labels=[];
      let data=[];
      let sum=[];
      let sumResult=0;
      for(let item of this.props.data){
          labels.push(serverDateToDayAndMonth(item.daily));
          data.push(item.result);
          sumResult+=item.result;
          sum.push(sumResult);
      }
      return {
         labels:labels,
         datasets:[
            {
               type: 'bar',
               label: 'Resultado Do Dia (R$)',
               backgroundColor: 'rgb(53, 162, 235)',
               yAxisID: 'y',
               fill: true,
               data: data,
             },
             {
               type:'line',
               fill:false,
               label: 'Soma (R$)',
               yAxisID: 'y',
               borderColor: 'rgb(75, 192, 192)',
               borderWidth: 2,
               data:sum,
             }
         ]
      }
   }
   getRadarData(){
         const results=this.props.data.map((item)=>item.result);
         const maxGain=Math.max(...results);
         const maxLoss=Math.min(...results);
         let positiveSum=0;
         let positiveDays=0;
         let negativeSum=0;
         let negativeDays=0;
         let totalSum=0;
         

         for(let result of results){
            totalSum+=result;
            if(result>0){
               positiveSum+=result;
               positiveDays+=1;
               continue;
            }
            negativeSum+=result;
            negativeDays+=1;
         }
         return{
            labels:['Maior Ganho (R$)',
                    'Maior Loss (R$)',
                    'Ganho Médio (R$)',
                    'Loss Medio (R$)',
                    'Retorno Esperado (R$)'],
            datasets:[
               {
                  label:'Informações adicionais',
                  data:[maxGain.toFixed(2),
                        maxLoss.toFixed(2),
                        (positiveSum/positiveDays).toFixed(2),
                        (negativeSum/negativeDays).toFixed(2),
                        (totalSum/results.length).toFixed(2)],
                  backgroundColor:['rgb(8, 133, 42)',
                                   'rgb(176, 33, 10)',
                                   'rgb(8, 133, 42)',
                                   'rgb(176, 33, 10)',
                                   'rgb(8, 133, 42)'],
                  borderWidth:1,

               }
            ]
         }
   }

   getDailyGainData(){
      let gainDays=0;
      let lossDays=0;
      for(let item of this.props.data){
         if(item.result>0){
           gainDays++;
           continue;
         }  
         lossDays++;
      }   
      return {
         labels:["Dias Lucrativos %","Dias Perdedores %"],
         datasets:[{
         data:[(100*gainDays/this.props.data.length).toFixed(2),
               (100*lossDays/this.props.data.length).toFixed(2)
            ],
         backgroundColor:['rgba(75, 192, 192, 0.2)','rgba(255, 99, 132, 0.2)'],
         borderColor:['rgba(75, 192, 192, 0.2)','rgba(255, 99, 132, 0.2)'],
         borderWidth: 1,}]
      }
   }
   render(){
       return (
         <React.Fragment>
        <div style={{display:"flex",
                     margin:"auto",
                     marginBottom:"20px",
                     width:"100%",
                     maxWidth:"800px",
                     alignItems:"center",
                     justifyContent:"space-around",
                     flexWrap:"wrap"
                     
                     }}>
            <div style={{minWidth:"250px",width:"35%"}}>
                <Pie data={this.getDailyGainData()} height="200px"/>
            </div>
            <div style={{minWidth:"250px",width:"35%"}}>
               <Chart type='bar' options={{indexAxis:'y'}} data={this.getRadarData()} height="200px"/>
            </div>
        </div>
        <div style={{width:"100%",maxWidth:"800px",margin:"auto"}}> 
           <Chart type='bar' data={this.getData()}/>
        </div>
        </React.Fragment>
       )
   }

}

function serverDateToDayAndMonth(date){
    let info=date.split('-');
    return `${info[2]}/${info[1]}`;
}